import React from 'react';
import Donut from './controls/MyDonut'
import Skill from './controls/MySkill'
import Cert from './controls/Cert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faTerminal, faCloud, faCodeMerge, faDatabase, faDesktop, faFeather } from '@fortawesome/free-solid-svg-icons'
import './css/TechStack.css'
var safe = require("./images/safe.png")
var ihk = require("./images/ihk.png")



const Contact = (props) => {
  return (
    <div className="area2">
      <div className="cap area2Start">
        Skills
      </div>
      <div className="cap area2Sub">
        My Expertise
      </div>
      <div className="donutArea" style={{marginTop: 80}}>


        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faTerminal} />}
          name="Backend"
          items={["C#", "ASP.Net", "WebAPI", 'LinQ', '.Net Core', 'Entity Framework']} />
        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faCloud} />}
          name="Cloud"
          items={["Azure", "Docker", "Kubernetes", "Terraform", "Helm"]} />
        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faCodeMerge} />}
          name="DevOps"
          items={["Azure DevOps", "Jenkins", "GitLab", "SonarQube", "CI/CD Pipelines"]} />

      </div>
      <div className="donutArea">

        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faDatabase} />}
          name="Database"
          items={["Oracle", "PostgreSQL", "BigData", "DWH", "MS SQL Server"]} />
        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faDesktop} />}
          name="Fontend"
          items={["React", "TypeScript"]} />
        <Skill icon={<FontAwesomeIcon className='skillIcon' icon={faFeather} />}
          name="Others"
          items={["GraphQL", "TDD / XUnit", "ElasticSearch", "MicroServices", "RabbitMQ", "gRPC"]} />

      </div>
      <div className="donutArea">
        <Cert image={<img src={safe} width="100" />} name="SAFe Scrum Master" time="01.2022" />
        <Cert image={<img src={ihk} width="100" />} name="AEVO Ausbilderschein" time="08.2017" />
      </div>
    </div>
  )
}

export default Contact;

