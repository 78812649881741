import React from 'react';
import AuthorItem from './controls/AuthorItem'
import golem from './images/golem.png'


const Author = (props) => {
    return (
      <div className="area2">
      <div className="cap area2Start">
        Being an Author
      </div>
      <div className="cap area2Sub">
        Sharing Knowledge
      </div>
      <div className="donutArea">
        <table>
          <AuthorItem 
          released="05.2022"
          title="Berufseinstieg: Wie werde ich Software-Entwickler?" 
          url="https://www.golem.de/news/berufseinstieg-wie-werde-ich-software-entwickler-2205-163320.html" />
          <AuthorItem
          released="08.2022" 
          title="Vom Anfänger zum Profi - Was macht einen Senior-Entwickler aus?" 
          url="https://www.golem.de/news/vom-anfaenger-zum-profi-was-macht-einen-senior-entwickler-aus-2208-164710.html" />
          <AuthorItem
          released="09.2022" 
          title="Leider nicht mal 'ausreichend' - Mein Erfahrungsbericht als IT-Lehrer" 
          url="https://www.golem.de/news/berufsschule-fuer-die-it-branche-leider-nicht-mal-ausreichend-2209-168537.html" />
          <AuthorItem
          released="09.2022" 
          title="Azure - Der Einstieg in die Cloud leicht gemacht" 
          url="https://www.golem.de/news/cloud-services-to-go-was-koennen-azure-aws-co-2209-167920.html" />
          <AuthorItem
          released="11.2022" 
          title="gRPC - Goggles Remote Procedure Calls vs. REST" 
          url="https://www.golem.de/news/neues-framework-grpc-googles-angriff-auf-rest-2211-169281.html" />
          <AuthorItem
          released="11.2022" 
          title="Fiverr - Harter Preiskampf, wenig gute Projekte" 
          url="https://www.golem.de/news/it-auftragsplattform-fiverr-harter-preiskampf-wenig-gute-projekte-2211-169610.html" />
          <AuthorItem
          released="11.2022" 
          title="Super-Programmierer - Von den Besten lernen" 
          url="https://www.golem.de/news/super-programmierer-von-den-besten-lernen-2211-169122.html" />
          <AuthorItem
          last={true}
          released="01.2023" 
          title="Monolithen gegen Microservices" 
          url="https://www.golem.de/news/softwarearchitektur-monolithen-gegen-microservices-2301-170448.html" />
        </table>
      <table>
       
      </table>
      
      
      </div>
    </div>
    )
}

export default Author;

