import React from 'react';
import Skillitem from './MySkillItem'

const Skill = (props) =>
{
    const section2Style = { 
        transform: "rotate("+props.radius+"deg)"
    };

    const section3StartStyle = { 
        transform: "rotate(" + (parseInt(props.radius) + 180) + "deg)"
    };

    const section3EndStyle = { 
        transform: "rotate(" + ( 360 - 180 - parseInt(props.radius)) + "deg)"
    };

        return (
          <div style={{ height: 350, width: 300}}>
            {props.icon} <br />
            <div style={{fontWeight: 500, height: 50, verticalAlign: 'center', textAlign: 'center', 
            fontFamily: 'RubikVariable,sans-serif', fontSize: 18, color: '#88c0d0'}}>
              {props.name} 
            </div>
            <div style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
            {props.items.map((item, i) => {     
            
              return (<Skillitem text={item} />) 
              })}
              </div>
          </div>
        )
}

export default Skill;