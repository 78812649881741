import React from 'react';
import './css/Training.css'
import { FaBook } from 'react-icons/fa'
import { GiTable } from 'react-icons/gi'

var basta = require("./images/basta.PNG")
var js = require("./images/js.PNG")
var udemy = require("./images/udemy.PNG")

const getIcon = (icon) => {
  switch (icon) {
    case "Book":
      return <FaBook />
      case "Training":
        return <GiTable />
    case "Basta":
      return <img src={basta} height="18" style={{ paddingTop: 3 }} />
    case "JS":
      return <img src={js} height="18" style={{ paddingTop: 3 }} />
    case "Udemy":
      return <img src={udemy} height="18" style={{ paddingTop: 3 }} />
  }
}

const Item = (year, icon, url, text) => {
  return (

    <div className="TrainingItem">
      <div className="Icon">
        {getIcon(icon)}
      </div>
      <a href={url} target="_blank">
        {text}
      </a>
      <div className="Year">
        {year}
      </div>
    </div>

  )
}

const Contact = (props) => {
  return (
    <div className="area6">
      <div className="cap area2Start">
        Training
      </div>
      <div className="cap area2Sub">
        Never stop learning. <br />
          Because life never stops teaching.<br />
      </div>

      <div className="TrainingArea">
        {Item(2022, "Training", "https://scaledagile.com/training/safe-scrum-master/", "SAFe Scrum Master")}
        {Item(2022, "Book", "https://www.amazon.de/Az-204-Developing-Solutions-Microsoft-Azure/dp/0136798330", "Azure Web Developer (AZ 204)")}
        {Item(2022, "Udemy", "https://www.udemy.com/course/azurecourse/", "Azure Web Developer")}

        {Item(2021, "Udemy", "https://www.udemy.com/course/microservices-architecture-and-implementation-on-dotnet/", "Microservices Architecture and Implementation on .NET 5")}
        {Item(2021, "Udemy", "https://www.udemy.com/course/meistere-die-asynchrone-programmierung-mit-net-und-csharp/", "Asynchrone Programmierung mit .Net und C#")}

        {Item(2020, "Udemy", "https://www.udemy.com/course/unit-testing-in-net/", "Unit Testing mit .net")}
        {Item(2020, "Training", "https://www.it-visions.de/start.aspx", " Software-Projektmanagement/Projektplanungsphase")}
        {Item(2020, "Book", "https://samnewman.io/books/monolith-to-microservices/", "Monolith to Microservices")}

        {Item(2019, "Book", "https://www.amazon.de/-/en/Robert-C-Martin/dp/0137081073", "The Clean Coder")}

        {Item(2018, "Basta", "https://basta.net/", "BASTA! 2018")}
        {Item(2018, "Book", "https://www.amazon.de/-/en/Robert-C-Martin/dp/0134494164", "Clean Architecture")}
        {Item(2018, "Book", "https://www.amazon.com/Clean-Code-Handbook-Software-Craftsmanship/dp/0132350882/", "Clean Code")}

        {Item(2017, "Training", "https://www.gfu.net/seminare-schulungen-kurse/java_sk5/design_pattern_mit_java_c_oder_csharp-entwickler_s721.html", "Entwurfsmuster für Java, C++ oder C#-Entwickler")}
        {Item(2017, "Book", "https://www.amazon.de/-/en/Eric-Freeman/dp/3897214210", "Entwurfsmuster von Kopf bis Fuß")}

        {Item(2016, "Basta", "https://basta.net/", "BASTA! 2016")}

        {Item(2015, "Book", "https://www.amazon.de/-/en/Robert-C-Martin/dp/0134494164", "Clean Architecture")}
        {Item(2015, "Book", "https://www.amazon.de/-/en/Mark-Seemann/dp/161729473X", "Dependency Injection")}

        {Item(2014, "JS", "https://javascript-days.de/", "JavaScript Days 2014")}

      </div>
    </div>
  )
}

export default Contact;

