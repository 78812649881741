import React from 'react';

const MyProjectNew = (props) => {
    return (
        <div style={{ height: 400, width: 300, textAlign: 'left', backgroundColor: 'black', padding: 20, borderRadius: 15 }}>
            <div style={{ display: '', justifyContent: 'center' }}>
                <img src={props.image} height="120" width="300"></img>
            </div>
            <hr></hr>
            <div style={{ display: 'flex', flexDirection: 'column', height: 150, justifyContent: 'center',  }}>
                <div style={{ fontStyle: '', marginBottom: 5 }}>
                    {props.title}
                </div>
                <div style={{ color: "#00CCFF", marginBottom: 5 }}>
                    {props.subTitle}
                </div>
                <div style={{ fontSize: 13, fontStyle: 'italic', marginBottom: 10 }}>
                    {props.text}
                </div>
            </div>
            <hr></hr>
            <div style={{ fontSize: 12, display: "flex", gap: 10, width: 300, height: 100, flexWrap: "wrap", justifyContent: 'center', alignContent: 'center' }}>
                {props.tech.map(element => {
                    return <div style={{
                        border: "1px solid white", padding: 5, margin: 0,
                        borderRadius: 5, whiteSpace: "nowrap",
                    }}>
                        {element}
                    </div>
                })}
            </div>
        </div>
    )
}

export default MyProjectNew;