import React, { useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai'
import Tooltip from 'react-power-tooltip'
import zert from '../images/zeugniss.png'
import golem from '../images/golem.png'

const AuthorItem = (props) => {
    const [myState, setState] = useState(false);

    return (
        <>
            <tr>
                <td>
                    <div style={{ }}>
                        <img src={golem} width="120" alt="Golem.de" />
                    </div>
                </td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>
                    <a href={props.url} target="_blank">
                        {props.title}
                    </a>
                </td>
            </tr>
            <tr style={{height: 30, verticalAlign: 'top', fontStyle: 'italic', fontSize: 12}}>
                <td>
                    Veröffentlicht: {props.released}
                </td>
            </tr>
            {
                props.last ?? 
                <>
                    <tr>
                    <hr></hr>
                    </tr>
                    <tr>
                        <br></br>
                    </tr>
                </>
            }
           
        </>
    )
}

export default AuthorItem;