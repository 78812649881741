import React from 'react';
import MyProject from './controls/MyProject'
import MyProjectNew from './controls/MyProjectNew'
import './css/Project.css'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import proj2 from './images/proj2.jpg'
import proj3 from './images/proj3.png'
import proj9 from './images/proj9.PNG'
import proj10 from './images/proj10.PNG'
import proj12 from './images/proj12.PNG'
import proj13 from './images/proj13.PNG'
import proj15 from './images/proj15.jpg'
import proj16 from './images/proj16.jpg'
import proj17 from './images/proj17.jpg'
import projMailing from './images/proj14.PNG'
import projacn from './images/bgacn.jpg'
import projlonc from './images/bglonc.jpg'
import insurence from './images/insurance.jpg'
import car from './images/car.jpg'
import tierarzt from './images/picTierarzt.jpg'
import kindergarten from './images/kindergarten.jpg'
import einv from './images/einv.png'
import umzuge from './images/umzuge.jpg'
import rsp from './images/rsp.jpg'
import com from './images/com.jpg'
import big from './images/big.jpg'
import flirt from './images/flirt.jpg'
import auktionen from './images/auktionen.jpg'

const Contact = (props) => {
  return (
    <div className="area5">
      <div className="cap area2Start">
        Project-Experience
      </div>
      <div className="cap area2Sub">
        Freelancing & More
      </div>

      <div>
      <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>
           {/* KPark */}
           <MyProjectNew
            image={proj17}
            title={props.getTranslation("projectTitleKPark")}
            subTitle="2022-2023"
            text={props.getTranslation("projectTextKPark")}
            tech={["Azure Cloud", "Azure DevOps", "C# .Net Core 6.0", "Docker", "Blazor", "Kubernetes"]}
          />
          {/* Bertrand */}
          <MyProjectNew
            image={proj15}
            title={props.getTranslation("projectTitleBertrand")}
            subTitle="2022-2023"
            text={props.getTranslation("projectTextBertrand")}
            tech={["Azure Cloud", "Azure DevOps", "Docker", "Kubernetes", "CI / CD", "Scrum"]}
          />
           {/* Zeiss */}
           <MyProjectNew
            image={proj16}
            title={props.getTranslation("projectTitleZeiss")}
            subTitle="2023"
            text={props.getTranslation("projectTextZeiss")}
            tech={["Azure Cloud", "Azure DevOps", "Docker", "Kubernetes", "BlackDuck", "SonarQube"]}
          />
        </div>
        <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>
          {/* Vets */}
          <MyProjectNew
            image={tierarzt}
            title={props.getTranslation("projectTitleVetz")}
            subTitle="2022"
            text={props.getTranslation("projectTextVetz")}
            tech={["Azure Cloud", "Azure DevOps", "C# .Net Core 6.0", "Azure Service Bus", "Docker", "Kubernetes"]}
          />
          {/* CPM */}
          <MyProjectNew
            image={car}
            title={props.getTranslation("projectTitleCPM")}
            subTitle="2021 - 2022"
            text={props.getTranslation("projectTextCPM")}
            tech={["Azure DevOps", "C# .Net FW 4.6", "RabbitMQ", "React", "TypeScript", "ElasticSearch"]}
          />
          {/* Zurich */}
          <MyProjectNew
            image={insurence}
            title={props.getTranslation("projectTitleZurich")}
            subTitle="2021 - 2022"
            text={props.getTranslation("projectTextZurich")}
            tech={["Azure Cloud", "Azure DevOps", "C# .Net 6.0", "Docker"]}
          />
        </div>
        <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>

        {/* Accenturee */}
        <MyProjectNew
          image={kindergarten}
          title={props.getTranslation("projectTitleACN")}
          subTitle="2021 - 2022"
          text={props.getTranslation("projectTextACN")}
          tech={["ASP.Net FW 4.6", "Oracle", "Jira", "Confluence", "Jenkins", "Chocolatey", "Ansible"]}
        />

        {/* Loncaric */}
        <MyProjectNew
          image={projlonc}
          title={props.getTranslation("projectTitleLONCARIC")}
          subTitle="2007 - 2021"
          text={props.getTranslation("projectTextLONCARIC")}
          tech={[".Net Core", "WCF"]}
        />

        {/* EInvoicing */}
        <MyProjectNew
          image={einv}
          title={props.getTranslation("projectTitle1")}
          subTitle="2020"
          text={props.getTranslation("projectText1")}
          tech={["ASP.Net Core", "TypeScript", "React", "C#.Net 7",
            "EF Core", "AWS", "TeamCity", "GraphQL"]}
        />
        </div>
        <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>

        {/* Umzüge */}
        <MyProjectNew
          image={umzuge}
          title={props.getTranslation("projectTitle2")}
          subTitle="2018-2019"
          text={props.getTranslation("projectText2")}
          tech={["ASP.Net Core", "TypeScript", "jQuery", "CSS3", "C#.Net 7", "EF Core", "AWS", "TeamCity"]}
        />

        {/*BigData Wurm */}
        <MyProjectNew
          image={big}
          title={props.getTranslation("projectTitle3")}
          subTitle="2016-2017"
          text={props.getTranslation("projectText3")}
          tech={["C#.Net FW", "Entity Framework", "LinQ", "MS SQL"]}
        />

        {/*  Rollenspiele */}
        <MyProjectNew
          image={rsp}
          title={props.getTranslation("projectTitle4")}
          subTitle="2013-2014"
          text={props.getTranslation("projectText4")}
          tech={["ASP.Net", "HTML5", "JavaScript", "JQuery", "CSS3", "C#.Net", "EF6"]}
        />
        </div>
        <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>

        {/* Animexx */}
        <MyProjectNew
          image={com}
          title={props.getTranslation("projectTitle5")}
          subTitle="2012"
          text={props.getTranslation("projectText5")}
          tech={["ASP.Net", "HTML5", "JavaScript", "JQuery", "CSS3", "C#.Net", "EF6"]}
        />

        {/* Mailing */}
        <MyProjectNew
          image={projMailing}
          title={props.getTranslation("projectTitle6")}
          subTitle="2012"
          text={props.getTranslation("projectText6")}
          tech={["ObjectiveC", "RestAPI", "JSON"]}
        />

        {/* Flirt */}
        <MyProjectNew
          image={flirt}
          title={props.getTranslation("projectTitle7")}
          subTitle="2010-2011"
          text={props.getTranslation("projectText7")}
          tech={["ASP.Net", "MS SQL 2005", "JQuery"]}
        />
        </div>
        <div style={{ display: 'flex', gap: 40, marginBottom: 40 }}>

        {/* Auktionen */}
        <MyProjectNew
          image={auktionen}
          title={props.getTranslation("projectTitle8")}
          subTitle="2009-2010"
          text={props.getTranslation("projectText8")}
          tech={["ASP.Net", "C#.Net", "HTML", "MS SQL 2005"]}
        />
        </div>

      </div>
    </div>
  )
}

export default Contact;

