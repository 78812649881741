import React from 'react';

const Cert = (props) =>
{
   
        return (
          <div style={{ height: 150, width: 300}}>
            <div style={{height: 100, marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {props.image}
            </div>
            <div style={{ color: '#88c0d0', marginBottom: 3}}>
              {props.name}
            </div>
            <div style={{fontStyle: 'italic', fontSize: 14}}>
              {props.time}
            </div>
          </div>
        )
}

export default Cert;