import React from 'react';

const MySkillItem = (props) =>
{
        return (
          <div style={{border: 'solid 1.5px lightblue', borderRadius: 10, margin: 5, padding: 10}}>
            {props.text}
          </div>
        )
}

export default MySkillItem;